import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'

import Layout from "../components/layout"
import BgImage from '../components/bg-image'

export default function Template({ data }) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <section className="section">
        <Helmet title={`Project | ${post.frontmatter.title}`} />
        <BgImage image={post.frontmatter.image} className="top">
          <h1>{post.frontmatter.title}</h1>
          <small>{post.frontmatter.date}</small>
        </BgImage>
        <div className="container content">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <Link to="/projects">
            <span aria-hidden="true">←</span> Back to all projects
          </Link>
        </div>
      </section>
    </Layout>
  )
}

/* eslint no-undef: 'off' */
export const pageQuery = graphql`
  query ProjectsByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
      }
      fields {
        slug
      }
    }
  }
`
